exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-application-js": () => import("./../../../src/pages/application.js" /* webpackChunkName: "component---src-pages-application-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiry-js": () => import("./../../../src/pages/inquiry.js" /* webpackChunkName: "component---src-pages-inquiry-js" */),
  "component---src-pages-kiyaku-js": () => import("./../../../src/pages/kiyaku.js" /* webpackChunkName: "component---src-pages-kiyaku-js" */),
  "component---src-pages-payment-campaign-js": () => import("./../../../src/pages/payment-campaign.js" /* webpackChunkName: "component---src-pages-payment-campaign-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-thankyou-application-js": () => import("./../../../src/pages/thankyou-application.js" /* webpackChunkName: "component---src-pages-thankyou-application-js" */),
  "component---src-pages-thankyou-trial-js": () => import("./../../../src/pages/thankyou-trial.js" /* webpackChunkName: "component---src-pages-thankyou-trial-js" */),
  "component---src-pages-tokushouhou-js": () => import("./../../../src/pages/tokushouhou.js" /* webpackChunkName: "component---src-pages-tokushouhou-js" */),
  "component---src-pages-trial-js": () => import("./../../../src/pages/trial.js" /* webpackChunkName: "component---src-pages-trial-js" */)
}

